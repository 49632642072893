import React, { useCallback, useRef } from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"

import * as css from "./privacy.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import metaPreviewImage from "../assets/images/thumbnails/thumbnail_index.jpg"
import MetaTags from "../components/MetaTags"

// markup
const ProFeatures = () => {
    return (
        <>
            <MetaTags
                metaDescription="Privasi"
                metaTitle="Privasi"
                metaPath="/privacy"
                metaPreviewImage={metaPreviewImage}
            />
            <Header />

            <Container className={css.section}>
                <Row>
                    <Col>
                        <h1>Privasi</h1>

                        <p>
                            Menjaga privasi adalah tugas paling penting bagi kami.
                            Sudah jadi bagian dari layanan aplikasi untuk menjaga privasi tetap aman terlepas dari segala informasi yang digunakan.
                            <br />

                        </p>

                        <h2>Data Pribadi</h2>
                        <p>
                            Ketika menggunakan aplikasi,
                            kami akan meminta anda menyediakan beberapa informasi pribadi untuk memastikan akun adalah benar-benar
                            autentik anda. Informasi yang dibutuhkan diataranya:

                        </p>
                        <ul>
                            <li>
                                Alamat Surel (<i>E-mail</i>)
                            </li>
                            <li>
                                Nama Depan dan Belakang
                            </li>
                            <li>
                                Nomor Telepon
                            </li>
                            <li>
                                Serta beberapa informasi lain tergantung jenis fitur yang dipilih.
                            </li>
                        </ul>


                        <h2> Penghapusan Data</h2>
                        <p> Anda bisa mengubah dan menghapus informasi kapan pun melalui halaman Dasbor.
                            Semua unggahan Twitter (<i>tweet</i>) juga otomatis terhapus jika anda menghapusnya di Twitter.
                        </p>

                        <h2>Tetap Waspada!</h2>
                        <p>
                            Kami berusaha meminimalkan risiko peretasan, salah satunya adalah dengan tidak menggunakan kata sandi untuk masuk ke aplikasi. Namun begitu
                            tidak memungkiri terjadi hal yang tidak diinginkan. <br />Sehingga kami meminta anda tetap waspada dan tidak memberikan informasi sensitif kepada pihak yang
                            mengaku sebagai pihak KTP Anabul atau Melon Kotak.<br /><br />

                            Selalu periksa jika anda masuk ke situs yang benar sebelum melakukan transaksi.
                        </p>


                        <h2>Tetap Terhubung</h2>
                        <p>
                            Untuk alasan keamanan, kami menyarankan untuk mengikuti kabar terbaru melalui kanal Twitter{" "}
                            <a href="https://x.com/huedaya">@huedaya <FontAwesomeIcon icon={faTwitter} /></a>{" "}
                            atau <a href="https://x.com/ktpanabul">@ktpAnabul <FontAwesomeIcon icon={faTwitter} /></a>.
                        </p>
                        <br />
                        <br />
                        <p> Pembaharuan Terakhir: 11 November 2022
                        </p>




                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default ProFeatures
